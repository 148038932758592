import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router-dom";
import StarsIcon from "@mui/icons-material/Stars";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useState } from "react";
import CartData from "../../../Data/services/cart.json";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useServiceCategory from "../../../hooks/api/useServiceCategory";
import SelectService from "./SelectService";
import { usePaginatedServices } from "../../SalonStaff/Admin/SystemManagement/ConfigureServices/configServiceApiHook";
import ServiceCard from "./ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import { ORDER_BOOK_ENDPOINT } from "../../../api/salon-api/log-book-service";
import { setOrderBook } from "../../../redux/feature/orderBookSlice";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";

function Service() {
  interface RootState {
    UploadLogo: {
      logo: string;
    };
  }

  interface ISelectedService {
    selectedService: {
      services: [
        {
          salonServicesId: number;
          startTime: string;
          cost: number;
          status: number;
          serviceName: string;
        }
      ];
      totalPrice: number;
    };
  }

  interface ISalonDetails {
    SalonDetails: {
      Salon_data: {
        name: string;
      };
    };
  }

  interface ICustomerToken {
    CustomerCred: {
      token: {
        id: number;
        customerName: string;
        uuid: string;
        mobileNo: number;
        email: string;
        gender: string;
        status: string;
        termAndCondition: string;
        salonId: number | null;
        dob: string;
        statusCode: number;
      };
      isCustomer: boolean;
      statusCode: 200;
    };
  }
  interface IAuthToken {
    auth: {
      email: string;
      token: string;
      data: {
        salonId: number;
        clientId: string;
        userRole: string;
        sub: string;
        iat: number;
        exp: number;
      };
      userRole: string;
      salonId: number;
      clientId: string;
    };
  }

  const location = useLocation();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateLogBook();

  const segment = location.pathname.split("/")[3];
  const Logo = useSelector((state: RootState) => state.UploadLogo.logo);
  const SelectedService = useSelector(
    (state: ISelectedService) => state.selectedService
  );
  const CustomerDetails = useSelector(
    (state: ICustomerToken) => state.CustomerCred.token
  );
  const Auth = useSelector((state: IAuthToken) => state.auth);

  const [checked, setChecked] = useState<number | undefined>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const SalonDetails = useSelector(
    (state: ISalonDetails) => state?.SalonDetails?.Salon_data
  );
  // console.log(location, segment, "this_is_location");
  console.log("customerCred", CustomerDetails);
  console.log("AuthCred", Auth);
  // const [price, setPrice] = useState(0);

  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [menu, setMenu] = useState(false);
  // const handleOpenMenu = () => setMenu(true);
  const handleCloseMenu = () => setMenu(false);

  const { serviceData } = usePaginatedServices(1, 2, false);
  const { loadingServiceCategory, serviceListData } = useServiceCategory({
    filters: { gender: segment, status: "ACTIVE" },
  });

  console.log(serviceListData, "___data___");

  const navigate = useNavigate();
  if (loadingServiceCategory || !serviceListData) return <div>Loading</div>;

  const handleCheckout = async () => {
    // console.log("working");
    if (SelectedService.services.length > 0) {
      const payload = {
        customerId: CustomerDetails?.id,
        salonId: Auth?.data.salonId,
        paidAmount: 0.0,
        totalCost: 0.0,
        discount: 0.0,
        status: "ACTIVE",
        paymentType: "CASH",
        serviceLogBookRequestList: SelectedService.services.map((item) => ({
          salonServicesId: item.salonServicesId,
          startTime: item.startTime,
          cost: item.cost,
          status: "WAITING",
        })),
      };

      // console.log("payload: ", payload);
      try {
        const response = await axiosPrivate({
          method: "POST",
          url: ORDER_BOOK_ENDPOINT,
          data: payload,
        });
        dispatch(setOrderBook({ id: response.data.id, data: [] }));
        navigate("/customer/checkout");
      } catch (error) {
        console.error("Error fetching Order Book data:", error);
        toast.error("Failed to process order. Please try again.");
      }
    } else {
      toast.warning("Please Select a Service");
    }
  };

  return (
    <>
      <div className="h-screen w-full grid relative">
        <div className="h-full sm:w-3/5 bg-slate-100 p-8 flex flex-col justify-between sm:overflow-hidden sm:grid">
          <div className="overflow-hidden w-full">
            <div className="py-7 ">
              <SelectService
                serviceListData={serviceData}
                setChecked={setChecked}
                checked={checked}
              />
            </div>
          </div>

          <div className="w-full border-2 border-gray-400 px-3 relative overflow-y-scroll hide-scrollbar rounded-lg">
            <div className="h-full w-full">
              <ServiceCard
                serviceListData={serviceData}
                setChecked={setChecked}
                checked={checked}
              />
            </div>
          </div>
        </div>

        <div className="h-full w-2/5 bg-gray-400 p-8 hidden sm:block absolute right-0 top-0">
          <div className=" w-full h-1/3 flex items-start justify-center flex-col">
            <p className="text-4xl font-bold mb-8">
              {SalonDetails?.name} is pleased to serve you.
            </p>
            <p className="text-black text-2xl">
              <StarsIcon className="text-black " fontSize="medium" /> 4.89 (5.0
              M Orders)
            </p>
          </div>

          <div className="bg-slate-100 rounded-lg w-full h-2/3 px-2 py-3 sm:grid overflow-hidden">
            <div className="w-full overflow-y-scroll hide-scrollbar border-b-2 border-slate-500 py-4 px-2 text-3xl font-semibold text-slate-600">
              Cart
            </div>
            <div className="w-full h-5/6 overflow-y-scroll py-4 px-2 hide-scrollbar">
              <div className="h-full w-full p-1 rounded-md text-center">
                {SelectedService.services.length > 0 ? (
                  SelectedService.services.map((item, index) => (
                    <div
                      className="flex justify-between items-center w-full py-3 mb-2 bg-slate-300"
                      key={index}
                    >
                      <p className="w-2/5 h-full text-wrap pl-2">
                        {item.serviceName}
                      </p>
                      <p className="text-wrap w-1/5 h-full">
                        &#8377; {item.cost}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400 text-xl italic">Cart is Empty</p>
                )}
              </div>
            </div>
            <div className=" w-full flex justify-start items-start pt-4">
              <button
                className="w-full rounded-md py-4 flex justify-between px-3 opacity-100 bg-blue-500 hover:bg-blue-700 transition duration-500 transform hover:opacity-0 hover:scale-150 text-white"
                onClick={handleCheckout}
              >
                <p>Checkout</p>
                {SelectedService?.totalPrice && (
                  <p>&#8377; {SelectedService.totalPrice}</p>
                )}
              </button>
            </div>
          </div>
        </div>

        <Modal
          open={menu}
          onClose={handleCloseMenu}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="w-full h-full flex justify-center items-center"
        >
          {/* <Box className="bg-black/[0.6] overflow-y-scroll hide-scrollbar relative"> */}

          <div className="h-full w-full bg-gray-400 p-8 overflow-y-scroll hide-scrollbar relative">
            <div className=" w-full flex items-start m-4 flex-col">
              <p className="font-bold text-xl">
                {SalonDetails?.name} is pleased to serve you.
              </p>
              <p className="text-black text-xs">
                <StarsIcon className="text-black " fontSize="small" /> 4.89 (5.0
                M Orders)
              </p>
            </div>

            <div className="bg-slate-100 rounded-lg w-full px-2 py-3 overflow-hidden">
              <div className="w-full overflow-y-scroll hide-scrollbar border-b-2 border-slate-500 py-4 px-2 text-3xl font-semibold text-slate-600">
                Cart
              </div>
              <div className="w-full h-5/6 overflow-y-scroll py-4 px-2 hide-scrollbar">
                <div className="h-full w-full p-1 rounded-md text-center">
                  {SelectedService.services.length > 0 ? (
                    SelectedService.services.map((item, index) => (
                      <div
                        className="flex justify-between items-center w-full py-3 mb-2 bg-slate-300"
                        key={index}
                      >
                        <p className="w-2/5 h-full text-wrap pl-2">
                          {item.serviceName}
                        </p>
                        <p className="text-wrap w-1/5 h-full">
                          &#8377; {item.cost}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-400 text-xl italic">
                      Cart is Empty
                    </p>
                  )}
                </div>
              </div>
              <div className=" w-full grid gap-4">
                <button
                  className="w-full rounded-md py-4 flex justify-between px-3 text-lg font-medium opacity-100 bg-blue-500 hover:bg-blue-700 transition duration-500 transform hover:opacity-0 hover:scale-150 text-white"
                  onClick={handleCheckout}
                >
                  <p>Checkout</p>
                  {SelectedService?.totalPrice && (
                    <p>&#8377; {SelectedService.totalPrice}</p>
                  )}
                </button>
                <button
                  className="w-full rounded-md py-4 px-3 opacity-100 bg-gray-300 text-lg font-medium transition duration-500 transform hover:opacity-0 hover:scale-150"
                  onClick={() => setMenu(false)}
                >
                  Close Cart
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {isMobile && (
        <div
          className="fixed bottom-0 left-0 right-0 w-full 
        bg-white p-3
        flex items-center justify-center
        shadow-lg
        "
        >
          <Badge
            badgeContent={SelectedService?.services?.length}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Button
              onClick={handleCheckout}
              variant="contained"
              className="w-full "
            >
              Checkout
            </Button>
          </Badge>
        </div>
      )}
    </>
  );
}

export default Service;
