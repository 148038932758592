import { useState, useEffect } from "react";
import useAxiosPrivate from "../useAxiosPrivate";
import { SERVICE_CATEGORY_ALL_ENDPOINT } from "../../api/salon-api/salon-service";
import {
  IServiceCategoryData,
  ServiceCategoryStatus,
} from "../../pages/SalonStaff/Admin/SystemManagement/ConfigureServices/types/configService";
import { useDispatch, useSelector } from "react-redux";
import { setServiceCategory } from "../../redux/feature/salonServiceSlice";
import { RState } from "../../redux/feature/slice";
import { toLowerCaseBase } from "../../helper/Common/text";

interface IServiceCategoryFilter {
  gender?: string;
  status?: ServiceCategoryStatus;
}

export interface IParams {
  filters?: IServiceCategoryFilter;
}

const useServiceCategory = (parm?: IParams) => {
  const { filters } = parm || {};
  const data = useSelector(
    (state: RState) => state?.services?.serviceCategories
  ) as IServiceCategoryData;
  const dispatch = useDispatch();
  const [loadingServiceCategory, setLoadingServiceCategory] = useState(false);
  const [serviceListData, setServiceListData] =
    useState<IServiceCategoryData | null>(null);
  const axiosPrivate = useAxiosPrivate();


  const filterData = (data: IServiceCategoryData) => {
    if (!filters || !data) {
      return data;
    }
    const { gender, status } = filters;
    if (gender && status) {
      return {
        ...data,
        content: data.content.filter((service) => {
          return (
            toLowerCaseBase(service.gender) === toLowerCaseBase(gender) &&
            service.status === status
          );
        }),
      };
    }
    if (gender) {
      return {
        ...data,
        content: data.content.filter(
          (service) =>
            toLowerCaseBase(service.gender) === toLowerCaseBase(gender)
        ),
      };
    }
    if (status) {
      return {
        ...data,
        data: data.content.filter((service) => service.status === status),
      };
    }
  };
  const salonId = useSelector((state: RState) => state?.auth.salonId);

  console.log(filterData(data), "filterData");

  const getSalonService = async () => {
    setLoadingServiceCategory(true);
    try {
      const result = await axiosPrivate({
        method: "POST",
        url: SERVICE_CATEGORY_ALL_ENDPOINT,
        data: { salonId },
      });
      dispatch(setServiceCategory(result.data));
      setServiceListData(filterData(result.data) as IServiceCategoryData);
      setLoadingServiceCategory(false);
    } catch (error) {
      console.log(error);
      setLoadingServiceCategory(false);
      setServiceListData(null);
    }
  };
  useEffect(() => {
    if (data) {
      setServiceListData(filterData(data) as IServiceCategoryData);
      return;
    }
    getSalonService();
  }, []);
  return {
    serviceListData /* !deprecated do not use serviceListData in future*/,
    loadingServiceCategory,
  };
};

export default useServiceCategory;
