import React, { useState, useEffect } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { IServiceDataLProps } from "./SelectService";
import { IMG_URL } from "../../../constants/baseURL";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedService,
  removeSelectedService,
} from "../../../redux/feature/selectedService";
import useAxiosPrivate, { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { CHECK_STAFF_FOR_SERVICE_AVAILABLE_IN_SALON } from "../../../api/salon-api/log-book-service";
import { RState } from "../../../redux/feature/slice";
import { ALL_SERVICE_BY_SERVICE_CATEGORY_ID } from "../../../api/salon-api/salon-service";

// Utility function to format date
const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

interface ISelectedService {
  salonServicesId: number;
  cost: number;
  startTime: string;
  status: number;
  serviceName: string;
}

interface RootState {
  selectedService: {
    services: ISelectedService[];
  };
  setChecked?: React.Dispatch<React.SetStateAction<number | undefined>>;
  checked?: number | undefined;
}

const ServiceCard = ({
  serviceListData,
  setChecked,
  checked,
}: IServiceDataLProps) => {
  const dispatch = useDispatch();
  const selectedServices = useSelector(
    (state: RootState) => state.selectedService.services
  );
  const SalonData = useSelector(
    (state: RState) => state.SalonDetails?.Salon_data
  );

  const [addedServices, setAddedServices] = useState<number[]>([]);
  const axiosPrivate = useAxiosPrivateLogBook();
  const axiosPrivateSalon = useAxiosPrivate()

  const [filteredData, setFilteredData] = useState(serviceListData);

  const handleRemoveServices = (salonServiceId: number | undefined) => {
    if (salonServiceId !== undefined) {
      dispatch(removeSelectedService(salonServiceId));
    }
  };

  const handleSelectedService = async (
    salonServiceId: number | undefined,
    serviceName: string,
    price: number
  ) => {
    if (salonServiceId !== undefined) {
      const payload = {
        salonServicesId: salonServiceId,
        cost: price,
        startTime: formatDate(new Date()), // Use the utility function here
        status: 1,
        serviceName: serviceName,
      };
      try {
        const response = await axiosPrivate({
          method: "GET",
          url: `${CHECK_STAFF_FOR_SERVICE_AVAILABLE_IN_SALON}/${salonServiceId}`,
        });
        if (response.data) {
          dispatch(addSelectedService(payload));
        } else {
          toast.warning("This Service is currrently unavailable !");
        }
      } catch (error) {
        console.error("Error Checking Available Staff for service", error);
        toast.error("Failed to process. Please try again.");
      }
    }
  };

  const fetchFilterData = async (id: number | undefined) => {
    if (!id) {
      setFilteredData(serviceListData);
      return;
    }
    const result = await axiosPrivateSalon({
      method: "GET",
      url: `${ALL_SERVICE_BY_SERVICE_CATEGORY_ID}/${id}`,
    });
    console.log("result", result);
    setFilteredData({ ...result.data, content: result.data });
  };

  useEffect(() => {
    fetchFilterData(checked);
  }, [checked]);

  // useEffect(() => {
  //   const serviceIds = selectedServices.map(
  //     (service) => service.salonServicesId
  //   );
  //   setAddedServices(serviceIds);
  // }, [selectedServices]);

  console.log("addedServices: ", serviceListData, selectedServices);

  return (
    <div>
      {filteredData?.content.map((item, index) => {
        const {
          id,
          imageS3path,
          serviceName,
          price,
          description,
          avgServeTime,
        } = item;
        const isAdded = selectedServices.find(
          (service) => service.salonServicesId === id
        );

        return (
          <div
            className="p-2 w-full bg-4 rounded-lg flex-col sm:flex-row flex sm:overflow-hidden my-6"
            key={index}
          >
            <div className="sm:w-1/2 p-4 sm:p-6 relative">
              <img
                className="img rounded-sm shadow-sm object-cover"
                src={`${IMG_URL.BASE_SERVICE_IMG}${imageS3path}`}
                alt={serviceName}
              />
            </div>
            <div className="sm:w-1/2 flex flex-col justify-between items-start p-8 text-wrap">
              <h3 className="text-2xl font-semibold">{serviceName}</h3>
              <div>
                <p className="text-xs pl-1">{description}</p>
                <p className="text-md font-light my-1">
                  <AccessTimeFilledIcon
                    className="text-blue-700"
                    fontSize="small"
                  />{" "}
                  {avgServeTime}
                </p>
                <p className="font-bold ml-1">&#8377; {price}</p>
              </div>
              {isAdded ? (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleRemoveServices(id)}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleSelectedService(id, serviceName, price)}
                >
                  Add
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServiceCard;
