export function truncateText(
  text: string,
  maxLength: number,
  ending = "..."
): string {
  // Check if the text length is less than or equal to the maximum length
  if (text.length <= maxLength) {
    return text;
  }

  // If the ending character length is greater than or equal to the maximum length,
  // return the original text (avoiding unexpected behavior)
  if (ending.length >= maxLength) {
    return text;
  }

  // Truncate the text to the maximum length minus the ending character length
  return text.substring(0, maxLength - ending.length) + ending;
}

export function toLowerCaseBase(text: string): string {
  // Convert the text to lowercase
  if (!text) return text;
  return text.toLowerCase();
}

export function capitalizeString(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
