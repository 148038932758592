import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ORDER_BOOK_ENDPOINT } from "../../../api/salon-api/log-book-service";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import { LoadingButton } from "@mui/lab";

export default function PaymentWindow({ Tip, TotalPrice }) {
  const navigate = useNavigate();
  const axiosPrivateLogBook = useAxiosPrivateLogBook();
  const PaymentQR = useSelector((state) => state?.paymentQR?.paymentQRCode);
  const OrderBook = useSelector((state) => state?.OrderBook);
  const SalonId = useSelector((state) => state?.SalonDetails?.Salon_data?.id);
  const CustomerId = useSelector((state) => state.CustomerCred.token.id);

  const [open, setOpen] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("CASH")

  /**
   * This function is called when the user clicks on the "Make Payment" button.
   * It will update the order book with the payment details and then navigate to the logout page.
   */
  const handlePaymentClick = async() => {
    await UpdateOrderBook(OrderBook.id)
    navigate("/logout");
  };

  const handleOpen = () => {
    setOpenQR(false);
    setOpen(!open);
  };

  const handleOpenQR = () => {
    setPaymentMethod("UPI")
    setOpen(!open);
    setOpenQR(!openQR);
  };

  const handleCancelPayment = () => {
    setOpen(false);
    setOpenQR(false);
  };

  const UpdateOrderBook = async (orderBookId) => {
      setLoading(true);
      try {
        await axiosPrivateLogBook({
          method: 'PUT',
          url: `${ORDER_BOOK_ENDPOINT}/${orderBookId}`,
          data: {
            customerId: CustomerId,
            salonId: SalonId,
            totalCost: Tip > 0 ? Tip + TotalPrice : TotalPrice,
            paymentType: paymentMethod,
          },
        });
        setLoading(false);
        handleOpen();
      } catch (error) {
        console.error('Error Updating Order Book data:', error);
        setLoading(false);
      }
  };

  return (
    <>
      <LoadingButton
        loading={loading}
        variant="contained"
        color="success"
        onClick={() => UpdateOrderBook(OrderBook.id)}
      >
        Pay
      </LoadingButton>

      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleOpen}>
        <DialogTitle>Select Your Preferred Payment Option</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack gap={2} display={"flex"} direction={"column"}>
              <Stack
                sx={{
                  height: "100px",
                  backgroundColor: "#E2E8F0",
                  cursor: "pointer",
                }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                direction="row"
                gap={2}
                onClick={handleOpenQR}
              >
                <img src="/upi.png" height="100px" width="100px" alt="UPI" />
                <Typography variant="h3">UPI</Typography>
              </Stack>
              <Stack
                sx={{
                  height: "100px",
                  backgroundColor: "#E2E8F0",
                  cursor: "pointer",
                }}
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={2}
                onClick={handlePaymentClick}
              >
                <img src="/cash.png" height="100px" width="100px" alt="Cash" />
                <Typography variant="h3">Cash</Typography>
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog fullWidth={true} maxWidth="sm" open={openQR} onClose={handleOpenQR}>
        <DialogTitle>Please Scan this QR to make Payment</DialogTitle>
        <DialogContent>
          <Stack gap={2} display={"flex"} direction={"column"}>
            <Stack
              sx={{ cursor: "default" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              direction="row"
              gap={2}
            >
              <img src={PaymentQR} alt="Payment QR Code" />
            </Stack>
          </Stack>
          <Stack gap={2} display={'flex'}>
            <DialogActions>
              <Button color="error" variant="outlined" onClick={handleCancelPayment}>
                Cancel
              </Button>
              <Button
                color="success"
                onClick={handlePaymentClick}
                variant="contained"
              >
                Payment Done
              </Button>
            </DialogActions>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
