import React, { useState } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Button as MUIButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import PopWindow from "../../../components/CustomerComponent/Checkout/PopWindow";
import { truncateText } from "../../../helper/Common/text";

function ItemsView({ data }) {
  const Services = data;
  const [isChange, setIsChange] = useState(false);
  console.log(Services, "Services___");

  const handleIsChange = () => {
    setIsChange(!isChange);
  };

  return (
    <>
      <Box border={1}>
        <Typography variant="h5" component="p">
          Items
        </Typography>
        <Box>
          <Stack spacing={2}>
            <Stack
              spacing={2}
              display={"flex"}
              flexWrap={"wrap"}
              gap={2}
              alignItems={"center"}
              border={1}
            >
              {Services.map((item) => {
                return (
                  <Stack>
                    <Box >
                      <Typography variant="h6" component="p">
                        {item?.salonServicesEntity?.serviceName}
                      </Typography>
                      <Typography variant="body1" component="p">
                        &#8377;{item?.salonServicesEntity?.price}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6" component="p">
                        {truncateText(item?.salonStaffEntity?.name, 10)}
                      </Typography>
                      <Box>
                        <Typography variant="body2">
                          <AccessTimeFilledIcon
                            fontSize="small"
                            sx={{ mr: 1 }}
                          />
                          Rating(5) : {item?.salonStaffEntity?.overAllRating}
                        </Typography>
                        <Typography variant="body2">
                          {item?.salonStaffEntity?.gender}
                        </Typography>
                      </Box>
                      <MUIButton
                        onClick={handleIsChange}
                        variant="contained"
                        color="primary"
                      >
                        Change Stylist
                      </MUIButton>
                    </Box>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <PopWindow
        isOpen={isChange}
        handleIsOpen={handleIsChange}
        orderBookId={Services?.id}
      />
    </>
  );
}

export default ItemsView;
