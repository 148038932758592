export const LOGBOOK_ENDPOINT = "v1/service-log-book/allLogBook";

export const ORDER_BOOK_ENDPOINT = "v1/order-book"

export const GET_ALL_STAFF_FOR_SERVICE = "v1/service-log-book/getAllStaffForService"

export const GET_ALL_LOG_BOOK_BY_ORDER_BOOK_ID = "v1/service-log-book/getAllLogBookByOrderBookId"

export const CHANGE_STAFF_BY_ID = "v1/service-log-book/changeStaff"

export const CHECK_STAFF_FOR_SERVICE_AVAILABLE_IN_SALON = "v1/service-log-book/checkStaffForServiceAvailableInSalon"