import React, { useState } from "react";
import { IConfigServiceData } from "../../SalonStaff/Admin/SystemManagement/ConfigureServices/types/configService";
import { useDispatch, useSelector } from "react-redux";
import { RState } from "../../../redux/feature/slice";
import { usePaginatedServicesCategory } from "../../SalonStaff/Admin/SystemManagement/ServiceCategory/configServiceCategoryApiHook";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { ALL_SERVICE_BY_SERVICE_CATEGORY_ID } from "../../../api/salon-api/salon-service";
import { setServiceData } from "../../../redux/feature/salonServiceSlice";
import { Checkbox } from "@mui/material";

export interface IServiceDataLProps {
  serviceListData?: IConfigServiceData | null;
  checked?: number | undefined;
  setChecked?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

function SelectService({
  serviceListData,
  checked,
  setChecked,
}: IServiceDataLProps) {
  console.log("serviceListData", serviceListData);

  const serviceCategoryData = useSelector(
    (state: RState) => state?.services?.serviceCategories?.content
  );
  const [filteredData, setFilteredData] = useState(serviceCategoryData);

  const PaginatedServicesCategory = usePaginatedServicesCategory();
  const dispatch = useDispatch();

  console.log("serviceCategoryData", serviceCategoryData);
  const axiosPrivate = useAxiosPrivate();

  const handleFilterService = async (id: number | undefined) => {
    if (setChecked) {
      setChecked((prev) => {
        if (prev === id) {
          return undefined;
        }
        return id;
      });
    }
    // try {
    //   const result = await axiosPrivate({
    //     method: "GET",
    //     url: `${ALL_SERVICE_BY_SERVICE_CATEGORY_ID}/${id}`,
    //   });
    //   console.log("result", result);
    //   setFilteredData(result.data);

    //   // dispatch(
    //   //   setServiceData({
    //   //     totalElements: result.data.length,
    //   //     totalPages: 1,
    //   //     page: 1,
    //   //     content: result.data,
    //   //   })
    //   // );
    // } catch (error) {
    //   console.log("error ::", error);
    //   toast.warn("Unable to Filter Data");
    // }
  };

  if (!serviceCategoryData || serviceCategoryData.length === 0) return null;
  return (
    <div className="border-2 border-gray-400 px-3 py-4 relative h-full w-full rounded-lg">
      <p className="absolute text-nowrap px-3 font-semibold italic -top-7 left-9 bg-slate-100 py-1 text-xl border-2 border-gray-400 rounded-lg">
        Select a Service
      </p>
      <div className="flex w-full flex-wrap gap-3">
        {serviceCategoryData
          .filter((item) => item.status === "ACTIVE")
          .map((item) => (
            <div
              key={item.id}
              className="flex items-center shadow-sm px-3 py-2 border-2 border-gray-400 rounded-lg bg-gray-900 text-white cursor-default"
              onClick={() => handleFilterService(item?.id)}
            >
              <Checkbox defaultChecked checked={checked === item?.id} />
              <div>{item.categoryName}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default SelectService;
