import React, { useEffect } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import DiscountIcon from "@mui/icons-material/Discount";
import { useDispatch, useSelector } from "react-redux";
import Ticket from "./ticket";
import ItemsView from "./item-view";
import PaymentSummary from "./paymentSummary";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import {
  GET_ALL_LOG_BOOK_BY_ORDER_BOOK_ID,
  LOGBOOK_ENDPOINT,
} from "../../../api/salon-api/log-book-service";
import { setOrderBook } from "../../../redux/feature/orderBookSlice";

function Checkout() {
  const navigate = useNavigate();
  const Logo = useSelector((state) => state.UploadLogo.logo);
  const OrderBook = useSelector((state) => state?.OrderBook || null);
  const customerId = useSelector((state) => state.CustomerCred.token.id);
  const salonId = useSelector((state) => state.auth.data.salonId);

  console.log("OrderBook", OrderBook?.data, customerId, salonId);

  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateLogBook();

  const getOrderBookByCustomerId = async () => {
    try {
      const response = await axiosPrivate({
        method: "POST",
        url: `${LOGBOOK_ENDPOINT}`,
        data: {
          customerId: customerId,
          salonId: salonId,
        },
      });
      console.log("response", response.data.content);
      dispatch(setOrderBook({ id: customerId, data: response.data.content }));
    } catch (error) {
      console.error("Error fetching Order Book data:", error);
    }
  };

  const GetOrderBookDetails = async (id) => {
    if (id) {
      try {
        const response = await axiosPrivate({
          method: "GET",
          url: `${GET_ALL_LOG_BOOK_BY_ORDER_BOOK_ID}/${id}`,
        });
        dispatch(setOrderBook({ id: id, data: response.data }));
      } catch (error) {
        console.error("Error fetching Order Book data:", error);
      }
    }
  };

  useEffect(() => {
    GetOrderBookDetails(OrderBook?.id);
    getOrderBookByCustomerId();
  }, [OrderBook?.id]);

  if (!OrderBook?.data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="">
      <div className="">
        <div className="">
          <div className="">
            {OrderBook?.data && <ItemsView data={OrderBook?.data} />}
            <div className=" ">
              {" "}
              <DiscountIcon fontSize="large" className="" />{" "}
              Coupons & Offers{" "}
              <p className="">(currently unavailable)</p>
            </div>
          </div>

          <div className="">
            {OrderBook?.data && <Ticket ticketNo={OrderBook?.data} />}
          </div>
        </div>
        {OrderBook?.data?.map((item) => (
          <PaymentSummary key={item.id} TotalPrice={item.cost} Discount={0} />
        ))}

        <div className=" ">
          <p className=" ">Cancellation Policy</p>
          <p className="">
            Please pay the price only you are confirmed for the service at your
            own risk. Service payments will not be refunded
          </p>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
